<template>
    <div align="center" v-if="is_mounted">
        <br>
        <div align="center">
            <span class="text-h4">Menu gestione Fattura</span>
            <br>
        </div>
        
        <div class="row justify-center">
            
            <div class="col-12 col-md-12 q-pr-md" align="center">
                <hr>
                <QQButton label="Modifica dati Cliente" color="blue-grey" icon="mdi-alarm" size="md" @click.native="onModificaCliente()" />
                <QQButton label="Modifica dati Contratto" color="blue-grey" icon="mdi-alarm" size="md" @click.native="onModificaContratto()" v-if="pagina_sorgente === 'TabellaFattureNonEmesse'" />
                <QQButton label="Registro movimenti" color="blue-grey" icon="mdi-clipboard-check-multiple-outline" size="md" @click.native="onRegistroMovimenti()"/>
                <hr>
            </div>
            
            <div class="col-12 col-md-5 q-pr-md" align="left">
                <br>
                <fieldset>
                    <legend>Dati del Contraente</legend>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="domanda">Tipo persona: </td>
                                <td class="risposta">{{ cliente.tipo_persona}}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Cognome / Ragione sociale: </td>
                                <td class="risposta">{{ cliente.cognome }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Nome: </td>
                                <td class="risposta">{{ cliente.nome}}</td>
                            </tr>
                            <tr  v-if="cliente.tipo_persona === 'persona_fisica'">
                                <td class="domanda">Codice Fiscale: </td>
                                <td class="risposta">{{ cliente.codice_fiscale}}</td>
                            </tr>
                            <tr  v-if="cliente.tipo_persona !== 'persona_fisica'">
                                <td class="domanda">Partita IVA: </td>
                                <td class="risposta">{{ cliente.partita_iva}}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Professione: </td>
                                <td class="risposta">{{ cliente.professione}}</td>
                            </tr>
                            <template v-if="cliente.tipo_persona === 'persona_fisica'">
                                <tr>
                                    <td class="domanda">Sesso: </td>
                                    <td class="risposta">{{ cliente.sesso }}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Data di nascita: </td>
                                    <td class="risposta">{{ cliente.data_nascita }}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Comune di nascita: </td>
                                    <td class="risposta">{{ cliente.comune_nascita }}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Provincia di nascita: </td>
                                    <td class="risposta">{{ cliente.provincia_nascita }}</td>
                                </tr>
                            </template>
                            <tr v-if="cliente.tipo_persona !== 'persona_fisica'">
                                <td class="domanda">Codice univoco destinatario: </td>
                                <td class="risposta">{{ cliente.codice_univoco_destinatario }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Indirizzo: </td>
                                <td class="risposta">{{ cliente.indirizzo_residenza }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Comune: </td>
                                <td class="risposta">{{ cliente.comune_residenza }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Cap: </td>
                                <td class="risposta">{{ cliente.cap_residenza }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Provincia: </td>
                                <td class="risposta">{{ cliente.provincia_residenza }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Telefono: </td>
                                <td class="risposta">{{ cliente.telefono }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Cellulare: </td>
                                <td class="risposta">{{ cliente.cellulare }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Email: </td>
                                <td class="risposta">{{ cliente.email }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Pec: </td>
                                <td class="risposta">{{ cliente.pec }}</td>
                            </tr>

                        </tbody>
                    </table>
                    <br>
                    
                </fieldset>
                <br>
                <fieldset>
                    <legend>Dati della Fattura</legend>

                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="domanda">Intermediario Capogruppo: </td>
                                <td class="risposta">{{ fattura.nome_intermediario_capogruppo }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Data di creazione: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(fattura.create_at) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Giorni rimanenti prima dell'invio all'Agenzia delle entrate: </td>
                                <td class="risposta">{{ fattura.giorni_rimasti }} giorni</td>
                            </tr>
                            <tr>
                                <td class="domanda">Premio contratto in Fattura: </td>
                                <td class="risposta">{{ fattura.premio }} Euro</td>
                            </tr>
                            <tr>
                                <td class="domanda">Consulenza totale in Fattura: </td>
                                <td class="risposta">{{ fattura.consulenza_totale }} Euro</td>
                            </tr>
                            <tr>
                                <td class="domanda">Premio complessivo di Fattura: </td>
                                <td class="risposta">{{ fattura.consulenza_totale + fattura.premio }} Euro</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <br>
            </div>

            <div class="col-12 col-md-5 q-pr-md" align="left">
                <br>
                <fieldset>
                    <legend>Dati della pratica</legend>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="domanda">Numero interno pratica: </td>
                                <td class="risposta">{{ pratica.numero }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Numero pratica: </td>
                                <td class="risposta">{{ pratica.numero_pratica }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Unit&agrave; Operatica: </td>
                                <td class="risposta">{{ pratica.unita_operativa }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Persona Operatica: </td>
                                <td class="risposta">{{ pratica.operatore }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Data di creazione: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(pratica.create_at) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Data di ultimo aggiornamento: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(pratica.update_at) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Data di definizione: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(pratica.defined_at) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Stato attuale della pratica: </td>
                                <td class="risposta">{{ pratica.stato }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    
                </fieldset>
                <br>
                <fieldset>
                    <legend>Dati del contratto</legend>

                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="domanda">Tipo di contratto: </td>
                                <td class="risposta">{{ contratto.prodotto_id }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Impresa di Assicurazione: </td>
                                <td class="risposta">{{ contratto.compagnia }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Numero Polizza Compagnia: </td>
                                <td class="risposta">{{ contratto.numero_polizza_compagnia }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Fornitore: </td>
                                <td class="risposta">{{ contratto.fornitore }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Decorrenza: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(contratto.decorrenza) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Scadenza contrattuale: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(contratto.scadenza) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Scadenza di rata: </td>
                                <td class="risposta">{{ AggiustaFormatoDate(quietanza.scadenza) }}</td>
                            </tr>
                            <tr>
                                <td class="domanda">Premio contratto: </td>
                                <td class="risposta">{{ quietanza.premio.toFixed(2) }} Euro</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    
                </fieldset>
                <br>
                <fieldset>
                    <legend>Dati economici</legend>

                    <table class="table">
                        <tbody>
                            <tr v-for="(dato_economico, index) in get_dati_economici"
                                v-bind:key="index"
                                v-bind="dato_economico">
                                <td class="domanda">{{ dato_economico.tipo }}: </td>
                                <td class="risposta">{{ dato_economico.importo }} Euro</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    
                </fieldset>
            </div>

        </div>

        <div align="center">
            <hr>
            
            <div class="row justify-center">
                <div class="col-md-4" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                
            </div>

            <br><br><br><br><br><br>
        </div>

    </div>

    
</template>

<script>

import { mapFields} from 'vuex-map-fields';
import { mapState,mapActions } from 'vuex';
import commonLib from "@/libs/commonLib";
import QQButton from "@/components/QQButton.vue";

export default {
    name: "SchedaFattura",
    components: {
        QQButton
    },
    data() {
        return {
            is_loading: true,
            is_mounted: false
        }
    },
    computed: {
        ...mapState({
            //is_loading: state => state.gestionePratiche.is_loading
        }),
        ...mapFields("gestioneFatture", {
            colonne: "colonne",
            righe: "righe",
            idpratica: "idpratica_selezionata",
            idcontratto: "idcontratto_selezionato",
            cliente: "cliente",
            pratica: "pratica",
            contratto: "contratto",
            quietanza: "quietanza",
            fattura: "fattura",
            dati_economici: "dati_economici",
            guid_pratica: "guid_pratica",
            pagina_sorgente: "pagina_sorgente"
        }),
        get_dati_economici() {
            return this.dati_economici.filter(de => {
                return de.da_sommare === true;
            })
        }
    },
    methods: {
        ...mapActions({
            InformazioniGeneraliFattura: "gestioneFatture/InformazioniGeneraliFattura"
        }),
        AggiustaFormatoDate(data) {
            console.log("data:",data);

            data = (data+"").substring(0,10);
            var elementi = data.split("-");
            console.log("elementi:",elementi);

            if (commonLib.isEmpty(elementi[1])) return data;

            return elementi[2]+"/"+elementi[1].padStart(2,'0')+"/"+elementi[0].padStart(2,'0'); 
        },
        onModificaCliente() {
            this.$router.push({name : "Fatture.ModificaDatiCliente"});
        },
        onModificaContratto() {
            this.$router.push({name : "Fatture.ModificaDatiContratto"});
        },
        onRegistroMovimenti() {
            this.$router.push({name : "Fatture.RegistroMovimenti"});
        },
        onIndietroClicked() {
            switch (this.pagina_sorgente) {
                case "":
                case "TabellaFattureNonEmesse":
                    this.$router.push({name : "Fatture.TabellaFattureNonEmesse"});
                break;

                case "TabellaFattureErrore":
                    this.$router.push({name : "Fatture.TabellaFattureErrore"});
                break;
            }
            
        }
    },
    async mounted() {
        this.guid_pratica = this.pratica.numero_pratica;

        var dati_request = {
            id_pratica: this.idpratica,
            id_contratto: this.idcontratto
        }

        var result = await this.InformazioniGeneraliFattura(dati_request);

        console.log("result: ",result);

        this.is_mounted = true;
    }

}

</script>

<style scoped>
    td.domanda {
        text-align: left;
        font-style: italic;
        border-bottom: 1px dashed #c0c0c0;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        font-weight: bold;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>